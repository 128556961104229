import React from "react"
import ButtonExternalLink from "../components/atoms/Button/buttonExternalLink"
import Layout from "../components/templates/layout"
import VectorGraphic from "../components/atoms/vectorGraphic"
import Grid from "../components/atoms/grid"

const Success = ({ location }) => {
  return (
    <Layout location={location} hideFooter hideNavigation>
      <div className="bg-blue-lightest relative">
        <Grid HTMLelement="figure" className="z-10 relative top-20">
          <VectorGraphic
            name="success"
            className="w-full col-span-4 sm:col-start-2 sm:col-end-8 lg:col-start-4 lg:col-end-10 px-10"
          />
        </Grid>
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 1440 240"
          fill="none"
          height="120"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full relative"
        >
          <path
            d="M0.5 239.5V0.614618C541.242 112.991 855.796 117.012 1439.5 0.60957V239.5H0.5Z"
            fill="white"
            stroke="white"
          />
        </svg>
      </div>
      <div className="pb-12 pt-5" innerClassName="pt-0">
        <Grid>
        <div className="text-center col-span-6 sm:col-start-2 lg:col-start-4 lg:col-end-10 mb-10">
          <h1 className="font-serif text-center text-32px mb-10">
            Payment Successful!
          </h1>
          <p className="text-center mb-10">
            Your payment was a success! You will receive a receipt for
            successful payment in your email, which will also let you know about
            next steps.
          </p>
          <ButtonExternalLink
            href="https://www.guarantid.com"
            label="Back to home"
            size="large"
            className="m-auto"
            theme="pink"
          />
        </div>
        </Grid>
      </div>
    </Layout>
  )
}

export default Success
